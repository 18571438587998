import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';
import { graphql } from 'gatsby';
import { SwCategoriesFilterBar } from '@lib/components/SwCategoriesFilterBar';
import SwEmptySection from '@lib/components/SwEmptySection';
import { SwFeaturedSection } from '@lib/components/SwFeaturedSection';
import { black, grey3, grey5, grey6 } from '@lib/components/bedrock/SwColors';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import SwSpacer from '@lib/components/bedrock/layout/SwSpacer';
import { SwSubNav } from '@lib/components/bedrock/navigation/SwSubNav';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import Articles from '@app/articles/Articles';
import FlashBanner from '@app/common/components/FlashBanner';
import Seo from '@app/common/components/Seo';
import SwLayout from '../components/v1/SwLayout';
import SwLine from '../components/v1/SwLine';
import SwPagination from '../components/v1/pagination/SwPagination';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';

const Header = styled('div')`
    padding-block-start: 32px;
    padding-block-end: 64px;
`;

const Blog = ({ pageContext, data }) => {
    const articles = data.allGraphCmsArticle.nodes;
    const { currentPage, pageCount } = data.allGraphCmsArticle.pageInfo;
    const { t } = useTranslation();

    const featuredArticle = data.pinnedArticles?.nodes[0];

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.resources.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <SwLayout background={grey6}>
                <SwContainer>
                    <Header>
                        <SwGrid columns={{ xs: ['100%'], md: ['60%', 'auto'] }} gap={32}>
                            <SwTypography color={grey3} component={'h1'} variant={'h3'}>
                                <SwTypography color={black} component={'span'} variant={'h3'}>
                                    {data.page.title}.
                                </SwTypography>{' '}
                                {data.page.subtitle}
                            </SwTypography>
                        </SwGrid>
                    </Header>
                </SwContainer>
                <SwFeaturedSection
                    abstract={featuredArticle.abstract}
                    category={featuredArticle.category}
                    coverImage={featuredArticle.coverImage}
                    slug={`${WEBSITE_MENU_ITEM[featuredArticle.category.usage.toLowerCase()].link}/${
                        featuredArticle.slug
                    }`}
                    tags={featuredArticle.tags}
                    title={featuredArticle.title}
                />
                <SwSubNav>
                    <SwCategoriesFilterBar
                        activeCategory={pageContext.category}
                        allCategoryLabel={t('all_stories')}
                        allCategoryLink={WEBSITE_MENU_ITEM[featuredArticle.category.usage.toLowerCase()].link}
                        group={data.articlesGroupByCategories.group}
                        lastRecordId={data.lastArticle.nodes[0].id}
                    />
                </SwSubNav>
                <SwContainer>
                    <Box display={'flex'} flexDirection={'column'} pt={4} width={'100%'}>
                        <div>
                            {articles.length > 0 ? (
                                <Articles articles={articles} />
                            ) : (
                                <>
                                    <SwSpacer direction={'vertical'} spacing={12} />
                                    <SwEmptySection />
                                    <SwSpacer direction={'vertical'} spacing={16} />
                                </>
                            )}
                            {pageCount > 1 && (
                                <>
                                    <SwLine color={grey5} spacing={2} spacingTop={8} />
                                    <SwPagination
                                        basePath={pageContext.basePath}
                                        currentPage={currentPage ?? 1}
                                        pageCount={pageCount}
                                    />
                                    <SwLine color={grey5} spacing={2} spacingBottom={4} />
                                </>
                            )}
                        </div>
                    </Box>
                </SwContainer>
                <SwContainer>
                    {articles.length > 0 && pageCount === 1 && (
                        <SwLine color={grey5} spacingBottom={3} spacingTop={4} />
                    )}
                </SwContainer>
                <SwWebsiteFooter newsletterForm={data.newsletterForm} />
            </SwLayout>
        </>
    );
};

export const Head = ({
    location: { pathname },
    pageContext,
    data: {
        page: { seo },
        enPage: { seo: enSeo }
    }
}) => (
    <Seo
        description={seo.description}
        image={enSeo.image?.url}
        noIndex={pageContext.noIndex ?? seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const pageQuery = graphql`
    query Articles(
        $limit: Int
        $skip: Int
        $category: String
        $usage: GraphCMS_ContentUsage
        $idToExclude: String
        $locale: GraphCMS_Locale
        $slug: String = "blog"
    ) {
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        pinnedArticles: allGraphCmsArticle(
            sort: { order: DESC, fields: published }
            limit: 1
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                category: { usage: { eq: $usage } }
                seo: { noIndex: { ne: true } }
            }
        ) {
            nodes {
                id
                title
                abstract
                slug
                category {
                    label
                    usage
                }
                tags {
                    id
                    label
                    color
                }
                coverImage {
                    alt
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
        }
        articlesGroupByCategories: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                category: { usage: { eq: $usage } }
                seo: { noIndex: { ne: true } }
            }
        ) {
            totalCount
            group(field: category___label, limit: 9) {
                nodes {
                    id
                }
                fieldValue
            }
        }
        lastArticle: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                category: { usage: { eq: $usage } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 1
        ) {
            nodes {
                id
            }
        }
        allGraphCmsArticle(
            sort: { order: DESC, fields: published }
            limit: $limit
            skip: $skip
            filter: {
                category: { label: { eq: $category }, usage: { eq: $usage } }
                locale: { eq: $locale }
                id: { ne: $idToExclude }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
        ) {
            nodes {
                id
                title
                slug
                published
                locale
                category {
                    label
                    usage
                }
                abstract
                tags {
                    id: remoteId
                    label
                    color
                }
                coverImage {
                    alt
                    gatsbyImageData(height: 290, layout: FIXED, placeholder: BLURRED)
                }
            }
            pageInfo {
                currentPage
                pageCount
                perPage
                totalCount
            }
        }
        enPage: graphCmsPage(slug: { eq: "blog" }, locale: { eq: en }) {
            seo {
                image {
                    url
                }
            }
        }
        page: graphCmsPage(slug: { eq: $slug }, locale: { eq: $locale }) {
            id: remoteId
            slug
            title
            seo {
                ...Essentials_GraphCMS_Seo
            }
            subtitle
        }
    }
`;

export default Blog;
