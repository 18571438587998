import React from 'react';
import { Grid } from '@mui/material';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';

const Articles = (props: ArticlesProps) => {
    return (
        <Grid component={'ul'} container={true} spacing={2}>
            {props.articles.map((article) => {
                return (
                    <React.Fragment key={article.id}>
                        <Grid component={'li'} item={true} md={4} sm={6} style={{ width: '100%' }} xs={12}>
                            <SwRecordCard
                                baseUrl={WEBSITE_MENU_ITEM[article.category.usage.toLowerCase()].link}
                                record={article}
                                titleHeading={'h2'}
                            />
                        </Grid>
                    </React.Fragment>
                );
            })}
        </Grid>
    );
};
interface ArticlesProps {
    articles: any[];
}

export default Articles;
